<template>
  <div class="botDetection-container">
    <v-toolbar flat>
      <v-toolbar-title>
        Bot Detection
      </v-toolbar-title>
      <v-btn color="second" dark to="/botDetectionv2" class="ml-2"
        >Bot Detection V2</v-btn
      >
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2">Sync</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
    <v-row>
      <v-col cols="2">
        <v-data-table
          :headers="headersUser"
          :items="$store.state.botDetection.ipAdressAndTypeGroupData"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                Login DeviceIdAndIpAdress
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <div>
              <span
                @click="
                  editItem(
                    'user.deviceInfo.deviceUniqueIdentifier',
                    item.name.deviceUniqueIdentifier
                  )
                "
              >
                {{ item.name.deviceUniqueIdentifier }}</span
              >
              <span
                @click="
                  editItem('user.deviceInfo.ipAdress', item.name.ipAdress)
                "
              >
                {{ item.name.ipAdress }}</span
              >
            </div>
          </template>
          <template v-slot:item.total="{ item }">
            <div style="color:yellow;">
              {{ item.total }}
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2">
        <v-data-table
          :headers="headersUser"
          :items="$store.state.botDetection.deviceData"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                Login DeviceId
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <div
              @click="
                editItem(
                  'user.deviceInfo.deviceUniqueIdentifier',
                  item.name.deviceUniqueIdentifier
                )
              "
            >
              {{ item.name.deviceUniqueIdentifier }}
            </div>
          </template>
          <template v-slot:item.total="{ item }">
            <div style="color:yellow;">
              {{ item.total }}
            </div>
          </template>
        </v-data-table>
      </v-col>
            <v-col cols="2">
        <v-data-table
          :headers="headersUser"
          :items="$store.state.botDetection.sigData"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                Sig
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <div
              @click="
                editItem(
                  'user.deviceInfo.sig',
                  item.name.sig
                )
              "
            >
              {{ item.name.sig }}
            </div>
          </template>
          <template v-slot:item.total="{ item }">
            <div style="color:yellow;">
              {{ item.total }}
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2">
        <v-data-table
          :headers="headers"
          :items="$store.state.botDetection.ipGroup"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Bonus Ip Adress </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.doc_count="{ item }">
            <div style="color:yellow;">{{ item.doc_count }}</div>
          </template>
          <template v-slot:item.key="{ item }">
            <span @click="editItem('user.deviceInfo.ipAdress', item.key)">{{
              item.key
            }}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="2">
        <v-data-table
          :headers="headers"
          :items="$store.state.botDetection.userIdGroup"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>User Bonus </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.doc_count="{ item }">
            <div style="color:yellow;">{{ item.doc_count }}</div>
          </template>
          <template v-slot:item.key="{ item }">
            <span @click="showUserDetail(item.key)">{{ item.key }}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-for="(itemx, index) in $store.state.botDetection.ipAndType"
        :key="index"
        cols="2"
      >
        <v-data-table
          :headers="headers"
          :items="itemx.total.buckets"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <span> {{ itemx.key }}</span>
                <span style="color:yellow;">
                  {{ itemx.doc_count | formatMoney }}</span
                >
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.doc_count="{ item }">
            <div style="color:yellow;">{{ item.doc_count }}</div>
          </template>
          <template v-slot:item.key="{ item }">
            <span @click="editItem('user.deviceInfo.ipAdress', item.key)">{{
              item.key
            }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog" scrollable fullscreen>
        <v-card>
          <v-card-title>
            <span class="headline">User Query</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <user-query-card
                  :field="field"
                  :val="searchValue"
                  :show="searchValue"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogUser" scrollable max-width="1600">
        <v-card>
          <v-card-title>
            <span class="headline"
              >User Detail
              <span style="color:yellow;">{{
                _.sum(
                  $store.state.botDetection.userIdDetail.map((m) => m.doc_count)
                ) | formatMoney
              }}</span>
            </span>

            <v-btn
              color="primary ml-2"
              dark
              @click="clicked = !clicked"
              class="mb-2"
              >User Detail</v-btn
            >

            <user-detail-modal-card :clicked="clicked" :userId="userId" />
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="$store.state.botDetection.userIdDetail"
                  :items-per-page="50"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:item.doc_count="{ item }">
                    <div style="color:yellow;">{{ item.doc_count }}</div>
                  </template>
                  <template v-slot:item.key="{ item }">
                    <span
                      @click="editItem('user.deviceInfo.ipAdress', item.key)"
                      >{{ item.key }}</span
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogUser = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import UserDetailModalCard from "../../components/Users/UserDetailModalCard.vue";
import UserQueryCard from "../logs/components/UserQueryCard.vue";
export default {
  components: { UserQueryCard, UserDetailModalCard },
  data() {
    return {
      current: {},
      deviceData: [],
      ipAdressGroupData: [],
      deviceAndIpData: [],
      ipAdressAndTypeGroupData: [],
      dialog: false,
      dialogUser: false,
      userId: null,
      clicked: false,
      headers: [
        {
          text: "total",
          align: "left",
          sortable: false,
          value: "doc_count",
        },
        { text: "key", value: "key" },
        // { text: "Actions", value: "action", sortable: false },
      ],
      headersUser: [
        { text: "total", value: "total" },

        {
          text: "name",
          align: "left",
          sortable: false,
          value: "name",
        },
        // { text: "Actions", value: "action", sortable: false },
      ],
      currentheaders: [
        {
          text: "name",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "total", value: "total" },
        // { text: "Actions", value: "action", sortable: false },
      ],
      field: null,
      searchValue: null,
    };
  },
  methods: {
    async save() {
      await this.$store.dispatch("botDetection/update", this.current);
      this.dialog = false;
      this.$store.dispatch("botDetection/getAll");
    },
    async editItem(field, val) {
      this.field = field;
      this.searchValue = val;
      this.dialog = true;
    },
    async showUserDetail(val) {
      let dt = new Date();
      dt.setDate(dt.getDate() - 1);
      let date = Math.round(dt.getTime() / 1000);
      this.userId = val;
      this.$store.dispatch("botDetection/getByUserIdBonus", {
        startDate: date,
        userId: val,
      });
      this.dialogUser = true;
    },

    async initialize() {
      let dt = new Date();
      dt.setDate(dt.getDate() - 1);
      let date = Math.round(dt.getTime() / 1000);
      this.$store.dispatch("botDetection/getdeviceData", 0);
      this.$store.dispatch("botDetection/getSigData", 2);
      this.$store.dispatch("botDetection/getIpAdressAndTypeGroupData", 1);
      this.$store.dispatch("botDetection/getByUserIdGroup", {
        startDate: date,
      });
      this.$store.dispatch("botDetection/getByIpGroup", { startDate: date });
      this.ipAdressAndTypeGroupData = await this.$store.dispatch(
        "botDetection/getByIpAndTypeGroup",
        { startDate: date }
      );
    },
  },
  async mounted() {
    this.initialize();
  },
};
</script>
<style>
.botDetection-container td,
.botDetection-container th {
  padding: 0 0 0 2px !important;
}
.botDetection-container .col-2 {
  padding: 10px 0 10px 12px !important;
  max-width: 14%;
}
</style>
